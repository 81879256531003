import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { setOrdersFilter, setOrderId } from '../../actions'
import {
    Button,
    Popconfirm,
    Drawer,
    Tabs,
    Badge,
    message,
    Dropdown,
    Menu,
    Icon,
    Divider,
    Modal,
    notification,
    Row,
    Col,
    Tooltip,
    PageHeader,
    Avatar,
    Carousel,
    Select,
} from 'antd'
import Table from '../Table'
import moment from 'moment'
import gql from '../../api/gql'
import CustomerInfo from '../orders/CustomerInfo'
import Comments from '../orders/Comments'
import UploadDesigns from '../products/UploadDesigns'
import _ from 'lodash'
import OrdersFilter from './OrdersFilter'
import OrdersToolbar from '../orders/OrdersToolbar'
import AddTracking from '../orders/AddTracking'
import EntityLogs from '../orders/EntityLogs'
import styled from 'styled-components'
import { history } from '../../history'
import ChangeVariant from '../orders/ChangeVariant'
import ChangeQuantity from '../orders/ChangeQuantity'
import UploadDesignPersonal from '../products/UploadDesignPersonal'
import { CLOUDFRONT_URL } from '../../config'
import OrderInfo from '../orders/OrderInfo'
import UploadLabel from '../orders/UploadLabel'
import { PushToDtf } from './PushToDtf'
const ContainerHover = styled.div`
    .ant-btn {
        display: none;
    }
    &:hover {
        .ant-btn {
            display: block;
        }
    }
`

const PER_PAGE = 20
export const ORDER_FIELD = `
  id,
  origin_id,
  origin_sku,
  product_sku,
  origin_number,
  price,
  base_cost,
  supplier_cost,
  quantity,
  created_at
  status
  shipping_cost
  amazon_order_id
  print_files {
        product_type_print_file_id
        title
        fileID
    }
  tracking{
    id
    tracking_code
  }
  customer{
    first_name
    last_name
    email
    phone
  }
  site{
    title
    user{
      first_name
      last_name
    }
  }
  supplier{
    id
    last_name
    first_name
    partner_fulfillment_id
  }
  carrier{
    id
    name
    url
  }
  billing{
    id
    address1
    address2
    city
    state
    state_code
    zip
    country
    country_code
  }
  shipping{
    id
    address1
    address2
    city
    state
    state_code
    zip
    country
    country_code
    phone
    last_name
    first_name
    method_title
  }
  product{
    id
    title
    images
    sku
    variants{
      id
      product_type_variant{
        id
        attributes{
          slug
          option
        }
      }
    }
  }
  variant{
    id
    sku
    product_type_id
    design_status
    print_files {
        id
        title
        file_id
    }
    product_type{
      state
      product_type_carriers{
        carrier{
          id
          name
        }
        price
        default
      }
      suppliers{
        id
        first_name
        last_name
      }
    }
    product_type_variant{
      id
      attributes{
        slug
        option
      }
        partner_variants{
            partner_fulfillment_id
            product_type_variant_id
            sku
        }
        mapping_partner_print_areas {
            partner_id
            partner_key
            product_type_print_area_id
            product_type_print_area_title
        }
    }
  }
  product_type_variant{
    id
    attributes{
      slug
      option
    }
    sku
    product_type{
      id
      title
      suppliers{
        id
        first_name
        last_name
      }
    }
    partner_variants{
        partner_fulfillment_id
        product_type_variant_id
        sku
    }
    mapping_partner_print_areas {
      partner_id
      partner_key
      product_type_print_area_id
      product_type_print_area_title
    }
  }
  personalized
  design_status
  product_name
  custom_order
  variant_image
  label_image_id
  new_partner_order_id
  partner_error
`
const TrackingWrapper = styled.div`
    .tracking-actions {
        font-size: 12px;
        opacity: 0;
        button {
            font-size: 12px;
        }
    }
    &:hover {
        .tracking-actions {
            opacity: 1;
        }
    }
`
const ProductCarousel = styled.div`
    .ant-carousel .slick-dots li button {
        background: #1890ff;
    }
    .ant-carousel .slick-dots li.slick-active button {
        background: #364d79;
    }
`
class Orders extends Component {
    state = {
        orders: [],
        aggs: [],
        selectedOrder: null,
        total: 0,
        filter: {
            ...this.props.filter,
            offset: 0,
            limit: PER_PAGE,
            aggs_by: 'status',
            api_order: false,
            site_ids: null,
        },
        page: 1,
        loading: false,
        showUpdateCustomer: false,
        showOrderComments: false,
        showUploadDesign: false,
        showEntityLogs: false,
        showEntityLogsTracking: false,
        showInfoOrder: false,
        orderSelect: [],
        selectCarrier: null,
        tracking_code: '',
        carrier_id: null,
        showAddTracking: false,
        addingTracking: false,
        carriers: [],
        loadingMenuIcon: 'down',
        showImage: false,
        loadingOk: false,
        loadingSave: false,
        changeVariant: false,
        changeQuantity: false,
        disableTracking: false,
        pushDtfState: {},
        pushDtfData: {},
        btnPushToDtfLoading: false,
        dtfId: 193,
    }

    componentDidMount() {
        this.loadOrders()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter !== this.props.filter) {
            this.setState(
                {
                    page: 1,
                    filter: {
                        ...this.props.filter,
                        offset: 0,
                        limit: PER_PAGE,
                        aggs_by: 'status',
                    },
                },
                () => this.loadOrders()
            )
        }
    }

    loadOrders() {
        const query = `query orders($filter: OrderFilter){
      orders(filter: $filter){
        count
        hits{
          ${ORDER_FIELD}
        },
        aggs{
          key
          doc_count
        }
      }
    }`
        this.setState({ loading: true })
        gql.request(query, {
            filter: this.state.filter,
        })
            .then((res) => {
                this.setState({
                    orders: res.orders.hits || [],
                    aggs: res.orders.aggs || [],
                    total: res.orders.count,
                    loading: false,
                })
            })
            .catch((err) => {
                // notification["error"]({
                //   message: _.get(err, "[0].message"),
                // });
                this.setState({ loading: false })
            })
    }

    statusFilter = (status) => {
        const { setOrdersFilter } = this.props
        setOrdersFilter({
            ...this.state.filter,
            status: status === 'all' ? null : [status],
        })
    }

    // pauseOrResumeOrder = (id, status) => {
    //   const name = status === "pending" ? 'resumeOrder' : 'pauseOrder'
    //   const mutation = `mutation ${name}($order_id: Int!){
    //     ${name}(order_id: $order_id)
    //   }`
    //   this.setState({ loading: true })
    //   gql.request(mutation, { order_id: id })
    //     .then(res =>
    //       message["success"](`Order has been ${status === "pending" ? "resume " : "pause"}`),
    //       this.setState({ loading: false }),
    //       this.loadOrders()
    //     )
    //     .catch(err => {
    //       message["error"](_.get(err, "[0].message"))
    //     })
    // }
    pauseOrResumeOrder = (id, status) => {
        const name = status === 'pending' ? 'resumeOrder' : 'pauseOrder'
        const mutation = `mutation ${name}($order_id: Int!){
      ${name}(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`
        this.setState({ loading: true })
        gql.request(mutation, { order_id: id })
            .then((res) => {
                message['success'](`Order has been ${status === 'pending' ? 'resumed ' : 'paused'}`)
                // this.setState({ loading: false }),
                // this.loadOrders()
                const orders = this.state.orders.map((el) => {
                    if (el.id === id) {
                        if (name === 'pauseOrder') {
                            return res.pauseOrder
                        } else {
                            return res.resumeOrder
                        }
                    } else return el
                })
                this.setState({ orders, loading: false })
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
            })
    }
    cancelOrder = (id) => {
        const mutation = `mutation cancelOrder($order_id: Int!){
      cancelOrder(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`
        gql.request(mutation, { order_id: id })
            .then((res) => {
                message['success'](`Order has been cancel`)
                //this.loadOrders()
                const orders = this.state.orders.map((el) => {
                    if (el.id === id) {
                        return res.cancelOrder
                    } else return el
                })
                this.setState({ orders, loading: false })
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
            })
    }

    updateStatus = (id, status) => {
        const mutation = `mutation updateOrderStatus($order_id: Int!, $status: orderStatus){
            updateOrderStatus(order_id: $order_id, status: $status){
                ${ORDER_FIELD}
            }
        }`;
    
        gql
          .request(mutation, { order_id: id, status: status})
          .then((res) => {
            console.log(res)
            message["success"](`The order has been transferred to ${status}`);
            
            const orders = this.state.orders.map((el) => {
              if (el.id === id) {
                return res.updateOrderStatus;
              } else return el;
            });
    
            this.setState({ orders, loading: false });
          })
          .catch((err) => {
            message["error"](_.get(err, "[0].message"));
          });
    };

    pushToDtf = () => {
        console.log(this.state.pushDtfData)
        
        if (Object.entries(this.state.pushDtfData).length !== this.state.orderSelect.length) {
            notification.error({ message: "Complete order mapping."})

            return
        }

        const orderNotDtf = this.state.orderSelect.find((selected) => parseInt(selected?.supplier?.id) !== this.state.dtfId)

        if (orderNotDtf) {
            notification.error({ message: "Order " + orderNotDtf?.id + " is not from Color Ecommerce."})

            return
        }

        if (Object.keys(this.state.pushDtfData).length === 0) {
            notification.error({ message: "No mapping, please check again."})

            return
        }

        const orderMappingFailed = Object.entries(this.state.pushDtfData)
            .find(([key, value]) => (typeof value?.sku === 'undefined') || (typeof value?.shipping_method === 'undefined') || (value?.placement?.length <1))

        if (orderMappingFailed) {
            const orderIdFailed = orderMappingFailed[0] ?? ""
            notification.error({ message: "Order "+orderIdFailed+" is mapping failed."})

            return
        }

        this.setState({btnPushToDtfLoading: true})

        let data = []

        Object.entries(this.state.pushDtfData).forEach(([key, value]) => {
            let group = data?.find((item) => item.origin_number === value?.origin_number)
            let index = data?.findIndex((item) => item.origin_number === value?.origin_number)

            if (group) {
                const o = group?.orders?.find((i) => i?.order_id === key)

                if (! o) {
                    group.orders.push({
                        order_id: parseInt(key),
                        sku: value?.sku,
                        placement: value?.placement,
                        shipping_method: value?.shipping_method
                    })
                } 

                if (index !== -1) {
                    data.splice(index, 1)
                } 

                data.push(group)
            } else {
                let orders = []
                
                orders.push({
                    order_id: parseInt(key),
                    sku: value?.sku,
                    placement: value?.placement,
                    shipping_method: value?.shipping_method
                })

                data.push({
                    origin_number: value?.origin_number,
                    orders: orders,
                })
            } 
        });

        const mutationPushToDtf = `mutation dtfPushOrder($input: DtfRequestData!){
            dtfPushOrder(input: $input){
                success
                origin_number
                message
            }
        }`

        gql.request(mutationPushToDtf, { 
            input: {
                groups: data
            }
        })
        .then((res) => {
            let dtfPushOrder = res?.dtfPushOrder ?? []

            if (dtfPushOrder?.length > 0) {
                dtfPushOrder.forEach((order) => {
                    if (order?.success) {
                        notification.success({ message: `Order #${order?.origin_number} ${order?.message}` })
                    } else {
                        notification.error({ message: `Order #${order?.origin_number} ${order?.message}` })
                    }
                })

                this.setState({
                    pushDtfData: {}, 
                    orderSelect: [],
                    btnPushToDtfLoading: false
                })
            
                this.loadOrders()

                return
            }

            if (res?.errors?.length > 0) {
                this.setState({
                    pushDtfData: {}, 
                    orderSelect: [],
                    btnPushToDtfLoading: false
                })

                const resErr = res?.errors[0]
                notification.error({ message: resErr })

                return
            }
        })
        .catch((err) => {
            this.setState({
                pushDtfData: {}, 
                orderSelect: [],
                btnPushToDtfLoading: false
            })

            notification.error({ message: _.get(err, '[0].message') })
        })
    }

    handleChangePushToDtf = (id, data) => {
        if (data?.sku && data?.placement?.length > 0 && data?.shipping_method) {
            this.setState({pushDtfState: {
                ...this.state.pushDtfState,
                [id]: {
                    ...this.state.pushDtfState[id],
                    btnPushDtfDisable: false
                }
            }})
        } else {
            this.setState({pushDtfState: {
                ...this.state.pushDtfState,
                [id]: {
                    ...this.state.pushDtfState[id],
                    btnPushDtfDisable: true
                }
            }})
        }

        this.setState({pushDtfData: {
            ...this.state.pushDtfData,
            [id]: data
        }})
    }

    assignOrderToSupplier = (id) => (value) => {
        const mutation = `mutation AssignOrderSupplier($order_ids: [Int!], $supplier_id: Int!){
      AssignOrderSupplier(order_ids: $order_ids, supplier_id: $supplier_id){
        ${ORDER_FIELD}
      }
    }`
        this.setState({ loading: true })
        gql.request(mutation, { order_ids: [+id], supplier_id: +value }).then((res) => {
            const orders = this.state.orders.map((el) => {
                if (el.id === id) {
                    return res.AssignOrderSupplier[0]
                } else return el
            })
            message['success']('Assign supplier successfully')
            this.setState({ loading: false, orders: orders })
        })
    }
    assignOrderToCarrier = (carrier, id) => {
        const mutation = `mutation assignOrderCarrier($order_ids: [Int!], $carrier_id: Int!, $price: Float!){
      assignOrderCarrier(order_ids: $order_ids, carrier_id: $carrier_id, price: $price){
        ${ORDER_FIELD}
      }
    }`
        this.setState({ loading: true })
        gql.request(mutation, {
            order_ids: [id],
            carrier_id: carrier.carrier.id,
            price: carrier.price,
        }).then((res) => {
            const orders = this.state.orders.map((el) => {
                if (el.id === id) {
                    return res.assignOrderCarrier[0]
                } else return el
            })
            this.setState({ orders, loading: false })
        })
    }

    changeSupplier = (orders) => {
        const ordersId = orders.map((el) => el.id)
        const order = this.state.orders.map((el) => {
            if (ordersId.includes(el.id)) {
                return {
                    ...el,
                    supplier: orders[0].supplier,
                    carrier: orders[0].carrier,
                }
            } else return el
        })
        this.setState({ orders: order, orderSelect: [] })
    }

    deleteOrder = (id) => {
        let query = `mutation deleteOrder{deleteOrder(order_id:${id})}`
        gql.request(query)
            .then(() => {
                notification.success({ message: `Order #${id} has been deleted` })
                this.loadOrders()
            })
            .catch((err) => {
                notification.error({ message: _.get(err, '[0].message') })
            })
    }

    forceDeleteOrder = (id) => {
        let query = `mutation deleteOrderInfo{deleteOrderInfo(order_id:${id})}`
        this.setState({ loading: true })
        gql.request(query)
            .then(() => {
                notification.success({ message: `Order #${id} has been deleted` })
                this.setState({ loading: false }, () => this.loadOrders())
            })
            .catch((err) => {
                notification.error({ message: _.get(err, '[0].message') })
            })
    }

    loadOrderCarriers = (order_id) => {
        let query = `query order{
      order(id: ${order_id}){
        id,
        carrier{
          id
          name
        }
        variant{
          product_type_variant{
            id
            carrier_costs{
              id
              carrier_id
              cost
              default
            }
          }
          product_type{
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
        product_type_variant{
          product_type{
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
      }
    }`

        this.setState({ loadingMenuIcon: 'loading' })
        gql.request(query)
            .then((res) => {
                let carriers = res.order.custom_order
                    ? res.order.product_type_variant.product_type.product_type_carriers
                    : res.order.variant.product_type.product_type_carriers
                let variantCarrierCosts = res.order.variant.product_type_variant.carrier_costs
                carriers.map((cr) => {
                    const cost = variantCarrierCosts.filter((c) => c.carrier_id === cr.carrier.id)
                    if (cost && cost.length > 0) {
                        cr.price = cost[0].cost
                    }
                    return cr
                })
                this.setState({
                    carriers: carriers,
                    loadingMenuIcon: 'down',
                })
            })
            .catch((err) => {
                this.setState({
                    loadingMenuIcon: 'down',
                })
                notification.error({
                    message: _.get(err, '[0].message'),
                })
            })
    }

    refreshOrder = (id) => {
        const mutation = `mutation RefreshOrder($order_id: Int!){
      refreshOrder(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`
        this.setState({ loading: true })
        gql.request(mutation, { order_id: id })
            .then((res) => {
                message['success'](`Order has been refreshed`)
                const orders = this.state.orders.map((el) => {
                    if (el.id === id) {
                        return res.refreshOrder
                    } else return el
                })
                this.setState({ orders, loading: false })
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
            })
    }

    enterPayment = (id) => {
        const mutation = `mutation enterPayment($order_id: Int!){
      enterPayment(order_id: $order_id)
    }`
        this.setState({ loading: true })
        gql.request(mutation, { order_id: id })
            .then((res) => {
                message['success'](`Order has been moved to ready`)
                this.setState({ loading: false }, () => this.loadOrders())
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
                this.setState({ loading: false })
            })
    }
    updateTracking = (tracking_id) => {
        const mutation = `mutation reTrack($id: ID!){
     reTrack(id: $id)
    }`
        this.setState({ loading: true })
        gql.request(mutation, { id: tracking_id })
            .then((res) => {
                message['success'](`Retrack tracking success!`)
                this.setState({ loading: false }, () => this.loadOrders())
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
                this.setState({ loading: false })
            })
    }
    render() {
        const {
            orders,
            aggs,
            total,
            filter,
            loading,
            page,
            selectedOrder,
            showUpdateCustomer,
            showOrderComments,
            showUploadDesign,
            showAddTracking,
            showInfoOrder,
            addingTracking,
            loadingMenuIcon,
            showEntityLogs,
            showEntityLogsTracking,
            showImage,
            loadingOk,
            loadingSave,
            changeVariant,
            changeQuantity,
            pushDtfState,
        } = this.state
        const { currentUser } = this.props

        let isSupporter = currentUser.roles.find((el) => ['Supporter'].includes(el.name))
        //const isSupplier = this.props.currentUser && this.props.currentUser.roles.filter(el => ['Supplier'].includes(el.name)).length > 0 ? true : false
        const isAdmin = currentUser.roles.find((role) => ['Administrator'].includes(role.name))
        const columns = [
            {
                title: 'ID',
                width: 100,
                dataIndex: 'id',
                render: (id, order) => (
                    <div>
                        <p>{`N${order.id.padStart(6, '0')}`}</p>
                    </div>
                ),
            },
            {
                title: 'Origin Number / Origin ID',
                dataIndex: 'origin_number',
                width: 160,
                render: (origin_number, order) => (
                    <div>
                        {origin_number && <p>{`${origin_number}`}</p>}
                        <p style={{ fontStyle: 'italic' }}>{`${order.origin_id}`}</p>
                    </div>
                ),
            },
            {
                title: 'Amazon Order Id',
                dataIndex: 'amazon_order_id',
                width: 160,
                render: (amazon_order_id, order) => (
                    <Fragment>
                        {amazon_order_id && <p>{`${amazon_order_id}`}</p>}
                    </Fragment>
                ),
            },
            {
                title: 'Product / Variant',
                dataIndex: 'product',
                width: 350,
                render: (product, order) => (
                    <div style={{ display: 'grid', gridTemplateColumns: '70px auto' }}>
                        <Avatar
                            icon='picture'
                            src={
                                order.variant_image && order.variant_image.indexOf('http') === 0
                                    ? order.variant_image
                                    : product.images
                                    ? product.images[0].indexOf('http') === 0
                                        ? product.images[0]
                                        : `${CLOUDFRONT_URL}/${product.images[0]}`
                                    : null
                            }
                            // src={product.images ? product.images[0] : null}
                            shape='square'
                            size={60}
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                this.setState({ showImage: product.images })
                            }}
                        />
                        <div>
                            <Tooltip title={`Id: ${order.product.id}`}>
                                <p>{`${order.product.title}`}</p>
                            </Tooltip>
                            <ContainerHover style={{ display: 'flex', gap: 5 }}>
                                {order.variant.product_type_variant.attributes &&
                                    order.variant.product_type_variant.attributes.map(
                                        (a, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <p
                                                        style={{
                                                            marginBottom: 5,
                                                            fontStyle: 'italic',
                                                        }}>
                                                        <b style={{ textTransform: 'capitalize' }}>
                                                            {a.slug}
                                                        </b>
                                                        : {a.option}{' '}
                                                    </p>
                                                </Fragment>
                                            )
                                        }
                                    )}
                                <Button
                                    size='small'
                                    type='link'
                                    hidden={
                                        !_.includes(
                                            ['pending_design', 'pending', 'ready', 'processing'],
                                            order.status
                                        )
                                    }
                                    onClick={() => {
                                        this.setState({ changeVariant: order })
                                    }}>
                                    <Icon type='edit' theme='twoTone' />
                                </Button>
                            </ContainerHover>

                            {! order.variant.id && (
                                <>
                                    <p><b>Product Type</b>: {`${order?.product_type_variant?.product_type?.title}`}</p>
                                    {
                                        order?.product_type_variant?.attributes &&
                                        order?.product_type_variant?.attributes?.map((a, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <span style={{ marginBottom: 5, fontStyle: 'italic' }}>
                                                        <b>{a.slug}</b>: {a.option}{' '}
                                                    </span>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </>
                            )}

                            <p>
                                {' '}
                                <b style={{ textTransform: 'capitalize' }}>Origin sku</b>: {order.origin_sku}
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                title: 'Customer',
                dataIndex: 'customer',
                key: 'customer',
                width: 210,
                render: (id, order) => (
                    <div>
                        <p>{`${order.customer.first_name} ${order.customer.last_name}`}</p>
                        <p style={{ marginBottom: 0, fontStyle: 'italic' }}>
                            {order.customer.email}
                        </p>
                    </div>
                ),
            },
            {
                title: 'Tracking code admin',
                width: 220,
                dataIndex: 'tracking',
                render: (tracking, order) => {
                    if (tracking) {
                        let trackingUrl = order.carrier ? order.carrier.url : ''
                        if (trackingUrl) {
                            trackingUrl = trackingUrl.replace(
                                'TRACKING_CODE',
                                tracking.tracking_code
                            )
                        }
                        return (
                            <Fragment>
                                <TrackingWrapper>
                                    <a href={trackingUrl} target='_blank'>
                                        {tracking.tracking_code}{' '}
                                    </a>
                                    <span>
                                        <Button
                                            type='link'
                                            style={{ padding: 0 }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    carrier_id: order.carrier
                                                        ? order.carrier.id
                                                        : 0,
                                                    tracking_code: tracking.tracking_code,
                                                    showAddTracking: true,
                                                    selectedOrder: order,
                                                })
                                            }}>
                                            Edit
                                        </Button>
                                        <div className='tracking-actions'>
                                            <a
                                                href='/#'
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.setState({
                                                        showEntityLogsTracking: true,
                                                        selectedOrder: order,
                                                    })
                                                }}>
                                                View logs
                                            </a>

                                            <Button
                                                type='link'
                                                size='small'
                                                // loading={updateTrackingLoadings[tracking.id]}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.updateTracking(tracking.id)
                                                }}>
                                                Update
                                            </Button>
                                        </div>
                                    </span>
                                </TrackingWrapper>
                            </Fragment>
                        )
                    } else {
                        return (
                            <Fragment>
                                {['in_production', 'fulfilled'].includes(order.status) && (
                                    <Button
                                        type='link'
                                        style={{ padding: 0 }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.setState({
                                                showAddTracking: true,
                                                selectedOrder: order,
                                            })
                                        }}>
                                        Add Tracking
                                    </Button>
                                )}
                            </Fragment>
                        )
                    }
                },
                // render: (tracking, order) => tracking ? <>{tracking.tracking_code} <Icon type="edit" onClick={e => {
                //   e.preventDefault()
                //   this.setState({
                //     carrier_id: order.carrier.id,
                //     tracking_code: tracking.tracking_code,
                //     showAddTracking: true,
                //     selectedOrder: order
                //   })
                // }} /></> : order.status === 'in_production' ? <Button onClick={e => {
                //   e.preventDefault()
                //   this.setState({
                //     showAddTracking: true,
                //     selectedOrder: order
                //   })
                // }}>Add Tracking</Button> : null
            },
            {
                title: 'Designs',
                dataIndex: 'designs',
                width: 130,
                render: (_, order) => {
                    let status = order.personalized
                        ? order.design_status
                        : order.variant.design_status
                    return status ? (
                        <Button
                            icon='check'
                            type='primary'
                            ghost
                            onClick={(e) => {
                                e.preventDefault()
                                this.setState({
                                    showUploadDesign: true,
                                    selectedOrder: order,
                                })
                            }}>
                            View
                        </Button>
                    ) : (
                        <Button
                            icon='warning'
                            type='danger'
                            ghost
                            onClick={(e) => {
                                e.preventDefault()
                                this.setState({
                                    showUploadDesign: true,
                                    selectedOrder: order,
                                })
                            }}>
                            Upload
                        </Button>
                    )
                },
            },
            {
                title: 'Label',
                dataIndex: 'label_image_id',
                width: 120,
                render: (_, order) => {
                    return (
                        <UploadLabel 
                            orderId={order?.id}
                            fileId={order?.label_image_id} 
                        />
                    )
                }
            },
            {
                title: 'Sku',
                dataIndex: 'sku',
                width: 150,
                render: (_, order) => (
                    <p style={{ marginBottom: 5 }}>
                        {order.product_sku ? order.product_sku : order.product.sku}{' '}
                    </p>
                ),
            },
            {
                title: 'Suppliers',
                key: 'suppilers',
                width: 135,
                render: (_, order) => {
                    if (!order.supplier) {
                        return (
                            <Select
                                style={{ width: 200 }}
                                placeholder='Assign to supplier'
                                onChange={this.assignOrderToSupplier(order.id)}>
                                {order.variant.product_type.suppliers &&
                                    order.variant.product_type.suppliers.map((el) => (
                                        <Select.Option key={el.id} value={el.id}>
                                            {el.first_name} {el.last_name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        )
                    } else {
                        return `${order.supplier.first_name} ${order.supplier.last_name}`
                    }
                },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                width: 140,
                // fixed: "right",
                render: (status, order) => {
                    const item = orderStatus.find((el) => [status].includes(el?.key))
                    const partner_error = order?.partner_error?.[0] ?? null
                    return (
                        <>
                            {item  && (
                                <span style={{ color: `${item.color}` }}>
                                    {item.title} 
                                </span>
                            )}

                            {item?.key === 'error' && (
                                <div style={{ color: `${item.color}` }}>{partner_error}</div>
                            )} 
                        </>
                    )
                },
            },
            {
                title: 'QTY',
                dataIndex: 'supplier_cost',
                key: 'supplier_cost',
                width: 130,
                render: (id, order) => (
                    <div>
                        <ContainerHover style={{ display: 'flex', gap: 5 }}>
                            <span style={{ margin: 5 }}>
                                {order.quantity}
                            </span>
                            <Button
                                hidden={
                                    !_.includes(
                                        ['pending_design', 'pending', 'ready', 'processing'],
                                        order.status
                                    )
                                }
                                size='small'
                                type='link'
                                onClick={() => {
                                    this.setState({ changeQuantity: order })
                                }}>
                                <Icon type='edit' theme='twoTone' />
                            </Button>
                        </ContainerHover>
                        <p style={{ marginBottom: 0, fontStyle: 'italic' }}>
                            {moment(order.created_at).format('DD MMM YYYY, HH:mm:ss')}
                        </p>
                    </div>
                ),
            },
            {
                title: "COST",
                dataIndex: "base_cost",
                key: "base_cost",
                width: 80,
                render: (id, order) => (
                    <>
                        {order.base_cost > 0 && (
                          <div>
                            {`$${order.base_cost}`}
                          </div>
                        )}
                    </>
                ),
            },
            {
                title: "SHIPPING COST",
                dataIndex: "shipping_cost",
                key: "shipping_cost",
                width: 120,
                render: (id, order) => (
                    <>
                        {order.shipping_cost > 0 && (
                          <div>
                            {`$${order.shipping_cost}`}
                          </div>
                        )}
                    </>
                ),
            },
            {
                title: 'Shipping Method',
                width: 150,
                key: 'carrier',
                render: (_, order) => {
                    //const carriers = order.variant.product_type.product_type_carriers
                    const carriers = this.state.carriers
                    const menu = (
                        <Menu
                            onClick={(item) => {
                                let carrier = carriers.find((el) => el.carrier.id == item?.key)
                                this.assignOrderToCarrier(carrier, order.id)
                            }}>
                            {carriers &&
                                carriers.map((el) => (
                                    <Menu.Item
                                        key={el.carrier ? el.carrier.id : 0}
                                        value={el.carrier}>
                                        {el.carrier ? el.carrier.name : ''} (
                                        {el.price > 0 ? '$' + el.price : 'Free'})
                                    </Menu.Item>
                                ))}
                        </Menu>
                    )
                    if (!order.carrier) {
                        return (
                            <Dropdown
                                overlay={menu}
                                trigger={['click']}
                                onVisibleChange={(visible) => {
                                    if (visible) {
                                        this.setState({ carriers: [], selectedOrder: order })
                                        this.loadOrderCarriers(order.id)
                                    }
                                }}>
                                <a
                                    className='ant-dropdown-link'
                                    onClick={(e) => e.preventDefault()}>
                                    Select Carrier
                                    <Icon
                                        type={
                                            loadingMenuIcon === 'loading' &&
                                            selectedOrder &&
                                            order &&
                                            selectedOrder.id === order.id
                                                ? 'loading'
                                                : 'down'
                                        }
                                    />
                                </a>
                            </Dropdown>
                        )
                    } else {
                        return (
                            <div>
                                {/* {`${order.carrier.name} (${
                                    order.shipping_cost > 0 ? '$' + order.shipping_cost : 'Free'
                                }) `} */}

                                {order.carrier.name}

                                {order?.shipping?.method_title && (
                                    <p style={{ marginBottom: 0}}>
                                        {order?.shipping?.method_title}
                                    </p>
                                )}

                                {['pending', 'pending_design', 'processing'].includes(
                                    order.status
                                ) && (
                                    <Dropdown
                                        overlay={menu}
                                        trigger={['click']}
                                        onVisibleChange={(visible) => {
                                            if (visible) {
                                                this.setState({
                                                    carriers: [],
                                                    selectedOrder: order,
                                                })
                                                this.loadOrderCarriers(order.id)
                                            }
                                        }}>
                                        <a
                                            className='ant-dropdown-link'
                                            onClick={(e) => e.preventDefault()}>
                                            Edit
                                            <Icon
                                                type={
                                                    loadingMenuIcon === 'loading' &&
                                                    selectedOrder &&
                                                    order &&
                                                    selectedOrder.id === order.id
                                                        ? 'loading'
                                                        : 'down'
                                                }
                                            />
                                        </a>
                                    </Dropdown>
                                )}
                            </div>
                        )
                    }
                },
            },
            {
                title: 'Seller',
                dataIndex: 'seller',
                key: 'site',
                width: 150,
                render: (id, order) => (
                    <div>
                        <p>{`${order.site && order.site.user ? order.site.user.first_name : ''} ${
                            order.site && order.site.user ? order.site.user.last_name : ''
                        }`}</p>
                        <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{order.site.title}</p>
                    </div>
                ),
            },
            // {
            //   title: 'Email',
            //   dataIndex: 'customer.email',
            //   width: 250
            // },

            // {
            //   title: 'Carrier',
            //   width: 250,
            //   key: 'carrier',
            //   render: (_, order) => {
            //     const carriers = order.variant.product_type.product_type_carriers
            //     let defaultCarrier = carriers && carriers.find(el => el.default===true)
            //     if (!order.carrier){
            //       return<Select defaultValue={defaultCarrier? defaultCarrier.carrier.id: []} placeholder="Select carrier..." style={{width: 200}}
            //       onChange={(value) => {
            //         let carrier = carriers.find(el => el.carrier.id === value)
            //         this.assignOrderToCarrier(carrier, order.id)
            //       }}
            //       >
            //         {
            //           carriers && carriers.map(el =>
            //             <Select.Option key={el.carrier.id} value={el.carrier.id}>{el.carrier.name}: ${el.price}</Select.Option>
            //             )
            //         }
            //       </Select>
            //     } else {
            //       return `${order.carrier.name}: $${order.shipping_cost} `
            //     }
            //   }
            // },
            // {
            //   title: 'Date',
            //   dataIndex: 'created_at',
            //   width: 180,
            //   render: (created_at) => moment(created_at).format('MMM DD, YYYY')
            // },

            {
                title: 'Actions',
                dataIndex: 'id',
                key: 'actions',
                align: 'left',
                fixed: 'right',
                width: 210,
                render: (id, order) => {
                    return (
                    <div>
                        {(isAdmin || isSupporter) &&
                            ['pending', 'pending_design', 'processing', 'ready'].includes(
                                order.status
                            ) && (
                                <>
                                    <Tooltip
                                        title={
                                            order.status !== 'pending'
                                                ? 'Pause Order'
                                                : 'Resume Order'
                                        }>
                                        <Button
                                            icon={
                                                order.status !== 'pending'
                                                    ? 'pause'
                                                    : 'step-forward'
                                            }
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.pauseOrResumeOrder(id, order.status)
                                            }}
                                            size='small'
                                            style={{ color: '#1890ff' }}
                                        />
                                    </Tooltip>
                                    <Divider type='vertical' />
                                </>
                            )}
                        {['processing'].includes(order.status) && (
                            <Fragment>
                                <Tooltip title='Enter Payment'>
                                    <Popconfirm
                                        title='Enter Payment for this order?'
                                        onConfirm={() => this.enterPayment(id)}>
                                        <Button size='small' icon='dollar' />
                                    </Popconfirm>
                                </Tooltip>
                                <Divider type='vertical' />
                            </Fragment>
                        )}
                        {(isAdmin ||
                            ['pending', 'pending_design', 'processing'].includes(order.status)) && 
                            !['cancelled', 'fulfilled'].includes(order.status) && (
                                <Fragment>
                                    <Tooltip title='Cancel Order'>
                                        <Popconfirm
                                            title='Cancel this order?'
                                            onConfirm={() => this.cancelOrder(id)}>
                                            <Button
                                                style={{ color: 'red' }}
                                                size='small'
                                                icon='close'
                                            />
                                        </Popconfirm>
                                    </Tooltip>
                                    <Divider type='vertical' />
                                </Fragment>
                            )}

                        {(isAdmin && ['processing', 'error'].includes(order.status)) && (
                            <Fragment>
                                <Tooltip title='Ready'>
                                    <Popconfirm
                                        title='To ready?'
                                        onConfirm={() => this.updateStatus(id, 'ready')}>
                                        <Button
                                            style={{ color: 'rgb(102, 204, 204)' }}
                                            size='small'
                                        >Ready</Button>
                                    </Popconfirm>
                                </Tooltip>
                                <Divider type='vertical' />
                            </Fragment>
                        )}

                        {(isAdmin && ['in_production'].includes(order.status)) && (
                            <Fragment>
                                <Tooltip title='Fulfilled'>
                                    <Popconfirm
                                        title='To fulfilled?'
                                        onConfirm={() => this.updateStatus(id, 'fulfilled')}>
                                        <Button
                                            style={{ color: 'rgb(82, 196, 26)' }}
                                            size='small'
                                        >Fulfilled</Button>
                                    </Popconfirm>
                                </Tooltip>
                                <Divider type='vertical' />
                            </Fragment>
                        )}

                        {(
                            (isAdmin || isSupporter) &&
                            ['pending_design'].includes(order.status)) && 
                            (
                                <Fragment>
                                    <Tooltip title='Processing'>
                                        <Popconfirm 
                                            title='To processing?' 
                                            onConfirm={() => this.updateStatus(id, 'processing')}
                                        >
                                            <Button style={{ color: 'green' }} icon='right' />
                                        </Popconfirm>
                                    </Tooltip>
                                    <Divider type='vertical' />
                                </Fragment>
                            )}

                        {(isAdmin && ['ready', 'error'].includes(order.status)) && (
                            <Fragment>
                                <Tooltip title='In production'>
                                    <Popconfirm
                                        title='To in production?'
                                        onConfirm={() => this.updateStatus(id, 'in_production')}>
                                        <Button
                                            style={{ color: 'rgb(0, 207, 255)' }}
                                            size='small'
                                        >In production</Button>
                                    </Popconfirm>
                                </Tooltip>
                                <Divider type='vertical' />
                            </Fragment>
                        )}

                        {(
                            (isAdmin || isSupporter) &&
                            ['ready', 'error'].includes(order.status) && 
                            parseInt(order.supplier.id) === this.state.dtfId
                        ) &&
                            (
                                <>
                                    <Tooltip title='DTF data mapping'>
                                        <Button
                                            onClick={() => {
                                                this.setState({pushDtfState: {
                                                    ...pushDtfState,
                                                    [order.id]: {
                                                        ...pushDtfState[order.id],
                                                        show: true
                                                    }
                                                }})
                                            }}
                                        >
                                            DTF
                                        </Button>
                                    </Tooltip>

                                    <Modal
                                        title='DTF data mapping, complete all'
                                        visible={pushDtfState[order.id]?.show}
                                        onCancel={() => {
                                            this.setState({pushDtfState: {
                                                ...pushDtfState,
                                                [order.id]: {
                                                    ...pushDtfState[order.id],
                                                    show: false
                                                }
                                            }})
                                        }}
                                        footer={[
                                            <Button
                                                disabled={pushDtfState[order.id]?.btnPushDtfDisable ?? true}
                                                key={"modal-push-to-dtf"+order.id}
                                                type='primary'
                                                onClick={() => {
                                                    this.setState({pushDtfState: {
                                                        ...pushDtfState,
                                                        [order.id]: {
                                                            ...pushDtfState[order.id],
                                                            show: false
                                                        }
                                                    }})
                                                }}
                                            >
                                                Save
                                            </Button>
                                        ]}
                                    >
                                        {order?.product_type_variant && (
                                            <div style={{marginBottom: '15px'}}>
                                            <b>Product type</b>: {order?.product_type_variant?.product_type?.title}

                                            {order?.product_type_variant?.attributes &&
                                                order?.product_type_variant.attributes.map((a, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                    <p style={{ marginBottom: 0 }}>
                                                        <b>{a.slug}</b>: {a.option}{" "}
                                                    </p>
                                                    </Fragment>
                                                );
                                                })}
                                            </div>
                                        )}

                                        <PushToDtf
                                            partnerFulfillmentId={order.supplier.partner_fulfillment_id}
                                            printFiles={order.personalized ? order.print_files?.map(item => ({
                                                id: item?.product_type_print_file_id,
                                                title: item?.title,
                                                file_id: item?.fileID,
                                            })) : order.variant.print_files}
                                            onChange={(data) => {
                                                data.origin_number = order?.origin_number
                                                this.handleChangePushToDtf(order.id, data)
                                            }}
                                            stateValue={this.state.pushDtfData[order.id]}
                                            partnerVariant={order?.product_type_variant?.partner_variants?.[0] ?? order?.variant?.product_type_variant?.partner_variants?.[0]}
                                            mappingPartnerPrintAreas={order?.product_type_variant?.mapping_partner_print_areas ?? order?.variant?.product_type_variant?.mapping_partner_print_areas}
                                        />
                                    </Modal>    
                                </>
                            )}
                        {/* {(isAdmin ||
                            isSupporter ||
                            ['pending', 'pending_design', 'processing'].includes(order.status)) && (
                            <Fragment>
                                <Tooltip title='Force Delete Order'>
                                    <Popconfirm
                                        title='Force Delete Order this order?'
                                        onConfirm={() => this.forceDeleteOrder(id)}>
                                        <Button
                                            style={{ color: 'red' }}
                                            size='small'
                                            icon='delete'
                                        />
                                    </Popconfirm>
                                </Tooltip>
                                <Divider type='vertical' />
                            </Fragment>
                        )} */}
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key='1'>
                                        <Button
                                            type={'link'}
                                            style={{ width: '100%', color: 'rgba(0,0,0,0.56)' }}
                                            icon='idcard'
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    showUpdateCustomer: true,
                                                    selectedOrder: order,
                                                })
                                            }}>
                                            Customer Info
                                        </Button>
                                    </Menu.Item>
                                    {isAdmin && (
                                        <Menu.Item key='2'>
                                            <Button
                                                type={'link'}
                                                style={{ color: 'rgba(0,0,0,0.56)' }}
                                                icon='redo'
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.refreshOrder(order.id)
                                                }}>
                                                Refresh
                                            </Button>
                                        </Menu.Item>
                                    )}
                                    <Menu.Item key='3'>
                                        <Button
                                            type={'link'}
                                            style={{ color: 'rgba(0,0,0,0.56)' }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    showEntityLogs: true,
                                                    selectedOrder: order,
                                                })
                                            }}
                                            icon='message'>
                                            History
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item key='4'>
                                        <Button
                                            type={'link'}
                                            style={{ width: '100%', color: 'rgba(0,0,0,0.56)' }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    showInfoOrder: true,
                                                    selectedOrder: order,
                                                })
                                            }}
                                            icon='info-circle'>
                                            Show Full Info
                                        </Button>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}>
                            <Icon type='more' />
                        </Dropdown>
                    </div>
                )}
            },
        ]

        const orderStatus = [
            {
                key: 'pending',
                title: 'Pending',
                color: '#f94',
            },
            {
                key: 'pending_design',
                title: 'Pending Designs',
                color: '#f94',
            },
            {
                key: 'processing',
                title: 'Processing',
                color: '#f94',
            },
            {
                key: 'ready',
                title: 'Ready',
                color: 'blue',
            },
            // {
            //   key: 'await',
            //   title: 'Await',
            //   color: 'blue',
            // },
            {
                key: 'in_production',
                title: 'In Production',
                color: 'blue',
            },
            {
                key: 'fulfilled',
                title: 'Fulfilled',
                color: '#52c41a',
            },
            {
                key: 'refunded',
                title: 'Refunded',
                color: '#ff5500',
            },
            {
                key: 'cancelled',
                title: 'Cancelled',
                color: '#ff5500',
            },
            {
                key: 'error',
                title: 'Error',
                color: '#ff5500',
            },
        ]
        const tableWidth = _.sum(columns.map((c) => c.width))
        const pagination = {
            pageSize: filter.limit,
            total: total,
            current: page,
            showTotal: (total, range) => `${range} of ${total}`,
            onChange: (page, pageSize) => {
                this.setState(
                    {
                        page: page,
                        filter: {
                            ...filter,
                            offset: (page - 1) * pageSize,
                        },
                    },
                    () => this.loadOrders()
                )
            },
        }
        
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.props.setOrderId(selectedRowKeys)
                this.setState({ orderSelect: selectedRows })
            },
        }

        const extraPageHeader = <div style={{display:'flex', alignItems:'center'}}>
            <OrdersToolbar />
            <Button
                type={'primary'}
                loading={this.state.btnPushToDtfLoading} 
                disabled={this.state.orderSelect?.length < 1}
                onClick={() => this.pushToDtf()}
            >
                Push DTF
            </Button>
        </div>

        return (
            <div>
                <PageHeader
                    title='Internal Orders'
                    onBack={() => {
                        history.goBack()
                    }}
                    style={{ padding: '16px 0px' }}
                    extra={extraPageHeader}
                />
                    
                <Row type='flex' style={{ width: 'calc(100%)' }}>
                    <Col
                        span={24}
                        // lg={18}
                        style={{ display: 'flex', alignItems: 'center' }}>
                        <OrdersFilter
                            {...this.props}
                            orders={this.state.orderSelect}
                            onCompleted={(data) => this.changeSupplier(data)}
                        />
                    </Col>
                    {/* <Col span={0} lg={6} style={{ textAlign: "right" }}></Col> */}
                </Row>
                {/* <OrdersFilter {...this.props} orders={this.state.orderSelect} onCompleted={(data) => this.changeSupplier(data)} /> */}
                <Tabs
                    onChange={this.statusFilter}
                    style={{ marginBottom: 0 }}
                    activeKey={filter.status ? filter.status[0] : 'all'}>
                    {isAdmin && (
                        <Tabs.TabPane
                            tab={
                                <Badge
                                    overflowCount={9999999}
                                    count={_.sum(aggs.map((agg) => agg.doc_count))}
                                    style={{
                                        background: '#fff',
                                        color: '#000',
                                        border: '1px solid #f5f5f5',
                                        right: -20,
                                    }}>
                                    All
                                </Badge>
                            }
                            key='all'></Tabs.TabPane>
                    )}
                    {orderStatus.map((s) => {
                        if (!s.hide) {
                            return (
                                <Tabs.TabPane
                                    key={s?.key}
                                    tab={
                                        <Badge
                                            overflowCount={9999999}
                                            count={_.sum(
                                                aggs
                                                    .filter((agg) => agg?.key === s?.key)
                                                    .map((a) => a.doc_count)
                                            )}
                                            style={{ backgroundColor: s.color }}>
                                            {s.title}
                                        </Badge>
                                    }
                                />
                            )
                        }
                    })}
                </Tabs>
                <Table
                    scroll={{ x: tableWidth }}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={orders}
                    pagination={pagination}
                    // rowKey={({ id }) => id}
                    rowKey={(record) => record.id}
                    loading={loading}
                    isShowScroll={true}
                />
                <Drawer
                    title={`Update customer info #${selectedOrder ? selectedOrder.origin_id : ''}`}
                    onClose={() =>
                        this.setState({
                            showUpdateCustomer: false,
                            selectedOrder: null,
                        })
                    }
                    width='500px'
                    visible={showUpdateCustomer}>
                    {selectedOrder && (
                        <CustomerInfo
                            onClose={() =>
                                this.setState({
                                    showUpdateCustomer: false,
                                    selectedOrder: null,
                                })
                            }
                            onUpdate={() =>
                                this.setState(
                                    { showUpdateCustomer: false, selectedOrder: null },
                                    () => this.loadOrders()
                                )
                            }
                            order={selectedOrder}
                            currentUser={currentUser}
                        />
                    )}
                </Drawer>
                <Drawer
                    title={`Comments #${selectedOrder ? selectedOrder.origin_id : ''}`}
                    onClose={() =>
                        this.setState({
                            showOrderComments: false,
                            selectedOrder: null,
                        })
                    }
                    width='500px'
                    visible={showOrderComments}>
                    {selectedOrder && <Comments order_id={selectedOrder.id} />}
                </Drawer>
                <Drawer
                    width='800px'
                    title='Upload Designs'
                    onClose={(e) => this.setState({ selectedOrder: null, showUploadDesign: false })}
                    visible={showUploadDesign}>
                    {selectedOrder && !selectedOrder.personalized && !selectedOrder.custom_order && (
                        <UploadDesigns
                            product_id={selectedOrder.product.id}
                            product_variant_id={selectedOrder.variant.id}
                            product_type_id={selectedOrder.variant.product_type_id}
                            variant_image={selectedOrder.variant_image}
                            onClose={(e) =>
                                this.setState(
                                    {
                                        selectedOrder: null,
                                        showUploadDesign: false,
                                        selectedOrder: null,
                                    },
                                    () => this.loadOrders()
                                )
                            }
                        />
                    )}
                    {selectedOrder && (selectedOrder.personalized || selectedOrder.custom_order) && (
                        <UploadDesignPersonal
                            selectedOrder={selectedOrder}
                            onClose={(e) =>
                                this.setState({
                                    selectedOrder: null,
                                    showUploadDesign: false,
                                    selectedOrder: null,
                                })
                            }
                        />
                    )}
                </Drawer>
                <Drawer
                    width='500px'
                    title='Activities'
                    onClose={(e) => this.setState({ selectedOrder: null, showEntityLogs: false })}
                    visible={showEntityLogs}>
                    <EntityLogs order='Order' selectedOrder={selectedOrder} />
                </Drawer>
                <Drawer
                    width='500px'
                    title='View log'
                    onClose={(e) =>
                        this.setState({
                            selectedOrder: null,
                            showEntityLogsTracking: false,
                        })
                    }
                    visible={showEntityLogsTracking}>
                    <EntityLogs tracking='Tracking' selectedOrder={selectedOrder?.tracking} />
                </Drawer>
                <Drawer
                    title={`Order #${selectedOrder ? selectedOrder.origin_id : ''}`}
                    onClose={() =>
                        this.setState({
                            showInfoOrder: false,
                            selectedOrder: null,
                        })
                    }
                    width='500px'
                    visible={showInfoOrder}>
                    {selectedOrder && (
                        <OrderInfo
                            onClose={() =>
                                this.setState({
                                    showInfoOrder: false,
                                    selectedOrder: null,
                                })
                            }
                            order={selectedOrder}
                        />
                    )}
                </Drawer>
                {/**Add/Edit tracking code*/}
                <Modal
                    title='Add/Edit tracking code'
                    visible={showAddTracking}
                    destroyOnClose={true}
                    footer={[
                        <Button
                            disabled={loadingOk ? loadingOk : loadingSave ? loadingSave : false}
                            onClick={(e) =>
                                this.setState({
                                    showAddTracking: false,
                                    disableTracking: false,
                                })
                            }>
                            Cancel
                        </Button>,
                        <Button
                            hidden={
                                !selectedOrder?.tracking &&
                                selectedOrder?.status === 'in_production'
                            }
                            disabled={loadingOk || this.state.disableTracking}
                            loading={loadingSave}
                            onClick={() => this.setState({ loadingSave: true })}>
                            {'Save & Re-Submit TM'}
                        </Button>,
                        <Button
                            disabled={loadingSave || this.state.disableTracking}
                            form='add-tracking'
                            key='submit'
                            htmlType='submit'
                            type='primary'
                            onClick={(e) => this.setState({ loadingOk: true })}
                            loading={loadingOk}>
                            Save
                        </Button>,
                    ]}
                    onCancel={(e) => this.setState({ showAddTracking: false })}>
                    {selectedOrder && (
                        <AddTracking
                            setDisbaleTracking={(value) => {
                                this.setState({ disableTracking: value })
                            }}
                            order={selectedOrder}
                            onOk={loadingSave}
                            done={() =>
                                this.setState({ loadingSave: false, showAddTracking: false })
                            }
                            onClose={() =>
                                this.setState(
                                    {
                                        showAddTracking: false,
                                        loadingSave: false,
                                        loadingOk: false,
                                    },
                                    () => this.loadOrders()
                                )
                            }
                            setLoading={(value) => {
                                this.setState({ loadingOk: value })
                            }}
                        />
                    )}
                </Modal>
                <Modal
                    title='Image product'
                    visible={showImage ? true : false}
                    footer={false}
                    onCancel={() => {
                        this.setState({ showImage: false })
                    }}>
                    <ProductCarousel>
                        {showImage && (
                            <Carousel autoplay>
                                {showImage?.map((item, index) => (
                                    <img key={index} src={item} width={400} />
                                ))}
                            </Carousel>
                        )}
                    </ProductCarousel>
                </Modal>
                {changeVariant && (
                    <Modal
                        title='Change variant of product'
                        visible={changeVariant ? true : false}
                        onCancel={() => {
                            this.setState({ changeVariant: false })
                        }}
                        footer={false}>
                        <ChangeVariant
                            order={changeVariant}
                            refetch={() => {
                                this.loadOrders()
                                this.setState({ changeVariant: false })
                            }}
                        />
                    </Modal>
                )}
                {changeQuantity && (
                    <Modal
                        title='Change quantity'
                        visible={changeQuantity ? true : false}
                        onCancel={() => {
                            this.setState({ changeQuantity: false })
                        }}
                        footer={false}>
                        <ChangeQuantity
                            order={changeQuantity}
                            refetch={() => {
                                this.setState({ changeQuantity: false })
                                this.loadOrders()
                            }}
                        />
                    </Modal>
                )}
            </div>
        )
    }
}

export default connect(
    (state) => ({
        filter: state.orders.filter,
    }),
    { setOrdersFilter, setOrderId }
)(Orders)
