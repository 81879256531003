import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setOrdersFilter,setOrderId, resetOrderId } from "../../actions";
import {
  Button,
  Popconfirm,
  Drawer,
  Tabs,
  Badge,
  message,
  Dropdown,
  Menu,
  Icon,
  Divider,
  Row,
  Col,
  notification,
  Alert,
  Tooltip,
  PageHeader,
  Modal,
  Carousel,
  Select,
  Avatar,
} from "antd";
import Table from "../Table";
import moment from "moment";
import gql from "../../api/gql";
import CustomerInfo from "./CustomerInfo";
import Comments from "./Comments";
import UploadDesigns from "../products/UploadDesigns";
import _ from "lodash";
import OrdersToolbar from "./OrdersToolbar";
import FetchOrders from "./FetchOrders";
import { ORDER_FIELD } from "../admin/Orders";
import EntityLogs from "./EntityLogs";
import styled from "styled-components";
import { history } from "../../history";
import ChangeVariant from "./ChangeVariant";
import ChangeQuantity from "./ChangeQuantity";
import UploadDesignPersonal from "../products/UploadDesignPersonal";
import SellerOrderFilter from "../seller/SellerOrderFilter";
import { PushToDtf } from "../admin/PushToDtf";
const TrackingWrapper = styled.div`
  .tracking-actions {
    font-size: 12px;
    opacity: 0;
    button {
      font-size: 12px;
    }
  }
  &:hover {
    .tracking-actions {
      opacity: 1;
    }
  }
`;
const ContainerHover = styled.div`
  .ant-btn {
    display: none;
  }
  &:hover {
    .ant-btn {
      display: block;
    }
  }
`;
const ProductCarousel = styled.div`
  .ant-carousel .slick-dots li button {
    background: #1890ff;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #364d79;
  }
`;

const PER_PAGE = 20;
class ExternalOrders extends Component {
  state = {
    orders: [],
    aggs: [],
    selectedOrder: null,
    total: 0,
    filter: {
      ...this.props.filter,
      offset: 0,
      limit: PER_PAGE,
      aggs_by: "status",
      api_order: true,
      site_ids: this.props.currentUser.roles
        .map((role) => role.name)
        .includes("Administrator")
        ? null
        : this.props.currentSite
        ? [this.props.currentSite.id]
        : null,
    },
    page: 1,
    loading: false,
    showUpdateCustomer: false,
    showOrderComments: false,
    showUploadDesign: false,
    showFetchOrders: false,
    showEntityLogs: false,
    showEntityLogsTracking: false,
    showImage: false,
    selectedOrderCarrier: null,
    carriers: [],
    loadingMenuIcon: "down",
    initSetting: [],
    changeVariant: false,
    changeQuantity: false,
    pushDtfState: {},
    pushDtfData: {},
    btnPushToDtfLoading: false,
    dtfId: 193,
    orderSelect: [],
  };

  componentDidMount() {
    this.loadOrders();
    this.loadAppSetting();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filter !== this.props.filter ||
      this.props.currentSite?.id !== prevProps.currentSite?.id
    ) {
      // setOrdersFilter({
      //   ...this.props.filter,
      //   api_order: true,
      //   offset: 0,
      //   limit: PER_PAGE,
      //   aggs_by: "status",
      //   site_ids: this.props.currentUser.roles
      //     .map((role) => role.name)
      //     .includes("Seller")
      //     ? [this.props.currentSite.id.toString()]
      //     : this.props.filter.site_ids,
      // });
      this.setState(
        {
          page: 1,
          filter: {
            ...this.props.filter,
            api_order: true,
            offset: 0,
            limit: PER_PAGE,
            aggs_by: "status",
            // site_ids: this.props.currentUser.roles
            //   .map((role) => role.name)
            //   .includes("Seller")
            //   ? [this.props.currentSite.id.toString()]
            //   : this.props.filter.site_ids,
          },
        },
        () => this.loadOrders()
      );
    }
  }

  loadOrders() {
    const query = `query orders($filter: OrderFilter){
      orders(filter: $filter){
        count
        hits{
          ${ORDER_FIELD}
        },
        aggs{
          key
          doc_count
        }
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(query, {
        filter: {
          ...this.state.filter,
          site_ids: this.props.currentUser.roles
            .map((role) => role.name)
            .includes("Seller")
            ? [localStorage.getItem("apiOrderCurrentSite")]
            : this.props.filter.site_ids,
        },
      })
      .then((res) => {
        this.setState({
          orders: res.orders.hits || [],
          aggs: res.orders.aggs || [],
          total: res.orders.count,
          loading: false,
        });
      })
      .catch((err) => {
        // notification["error"]({
        //   message: _.get(err, "[0].message"),
        // });
        this.setState({ loading: false });
      });
  }

  loadOrderCarriers = (order_id) => {
    let query = `query order{
      order(id: ${order_id}){
        id,
        carrier{
          id
          name
        }
        custom_order
        variant{
          product_type_variant{
            id
            carrier_costs{
              id
              carrier_id
              cost
              default
            }
          }
          product_type{
            state
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
        product_type_variant{
          product_type{
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
      }
    }`;
    this.setState({ loadingMenuIcon: "loading" });
    gql
      .request(query)
      .then((res) => {
        let carriers = res.order.custom_order
          ? res.order.product_type_variant.product_type.product_type_carriers
          : res.order.variant.product_type.product_type_carriers;
        let variantCarrierCosts =
          res.order.variant.product_type_variant.carrier_costs;
        carriers.map((cr) => {
          const cost = variantCarrierCosts.filter(
            (c) => c.carrier_id === cr.carrier.id
          );
          if (cost && cost.length > 0) {
            cr.price = cost[0].cost;
          }
          return cr;
        });
        this.setState({
          carriers: carriers,
          loadingMenuIcon: "down",
        });
      })
      .catch((err) => {
        this.setState({
          loadingMenuIcon: "down",
        });
        notification.error({
          message: _.get(err, "[0].message"),
        });
      });
  };

  statusFilter = (status) => {
    const { setOrdersFilter, resetOrderId } = this.props;
    setOrdersFilter({
      ...this.state.filter,
      status: status === "all" ? null : [status],
    });

    resetOrderId()
  };

  pauseOrResumeOrder = (id, status) => {
    const name = status === "pending" ? "resumeOrder" : "pauseOrder";
    const mutation = `mutation ${name}($order_id: Int!){
      ${name}(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { order_id: id })
      .then((res) => {
        message["success"](
          `Order has been ${status === "pending" ? "resumed " : "paused"}`
        );
        // this.setState({ loading: false }),
        // this.loadOrders()
        const orders = this.state.orders.map((el) => {
          if (el.id === id) {
            if (name === "pauseOrder") {
              return res.pauseOrder;
            } else {
              return res.resumeOrder;
            }
          } else return el;
        });
        this.setState({ orders, loading: false });
        this.loadOrders();
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };

  cancelOrder = (id) => {
    const mutation = `mutation cancelOrder($order_id: Int!){
      cancelOrder(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`;
    gql
      .request(mutation, { order_id: id })
      .then((res) => {
        message["success"](`Order has been cancel`);
        //this.loadOrders()
        const orders = this.state.orders.map((el) => {
          if (el.id === id) {
            return res.cancelOrder;
          } else return el;
        });
        this.setState({ orders, loading: false });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };

  updateStatus = (id, status) => {
    const mutation = `mutation updateOrderStatus($order_id: Int!, $status: orderStatus){
        updateOrderStatus(order_id: $order_id, status: $status){
            ${ORDER_FIELD}
        }
    }`;

    gql
      .request(mutation, { order_id: id, status: status})
      .then((res) => {
        console.log(res)
        message["success"](`The order has been transferred to ${status}`);
        
        const orders = this.state.orders.map((el) => {
          if (el.id === id) {
            return res.updateOrderStatus;
          } else return el;
        });

        this.setState({ orders, loading: false });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };

  assignOrderToSupplier = (id) => (value) => {
    const mutation = `mutation AssignOrderSupplier($order_ids: [Int!], $supplier_id: Int!){
      AssignOrderSupplier(order_ids: $order_ids, supplier_id: $supplier_id){
        ${ORDER_FIELD}
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { order_ids: [+id], supplier_id: +value })
      .then((res) => {
        message["success"]("Assign supplier successfully");
        this.loadOrders();
        // const orders = this.state.orders.map((el) => {
        //   if (el.id === id) {
        //     return res.AssignOrderSupplier;
        //   } else return el;
        // });
        // this.setState({ loading: false, orders: orders });
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  };

  assignOrderToCarrier = (carrier, id) => {
    const mutation = `mutation assignOrderCarrier($order_ids: [Int!], $carrier_id: Int!, $price: Float!){
      assignOrderCarrier(order_ids: $order_ids, carrier_id: $carrier_id, price: $price){
        ${ORDER_FIELD}
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, {
        order_ids: [id],
        carrier_id: carrier.carrier.id,
        price: carrier.price,
      })
      .then((res) => {
        const orders = this.state.orders.map((el) => {
          if (el.id === id) {
            return res.assignOrderCarrier[0];
          } else return el;
        });
        this.setState({ orders, loading: false });
      });
  };

  fetchOrdersHandle = () => {
    this.setState({ showFetchOrders: true });
  };

  loadAppSetting() {
    const query = `query getAppSettings($input: [String!]){
      getAppSettings(input: $input){
        name
        value
      }
    }`;
    const input = ["seller_message"];
    this.setState({ loading: true });
    gql
      .request(query, { input: input })
      .then((res) => {
        this.setState({
          initSetting: res.getAppSettings || [],
          loading: false,
        });
      })
      .catch((err) => {
        notification["error"]({
          message: "Error",
          description: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  }

  enterPayment = (id) => {
    const mutation = `mutation enterPayment($order_id: Int!){
      enterPayment(order_id: $order_id)
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { order_id: id })
      .then((res) => {
        message["success"](`Order has been moved to ready`);
        this.setState({ loading: false }, () => this.loadOrders());
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
        this.setState({ loading: false });
      });
  };

  updateTracking = (tracking_id) => {
    const mutation = `mutation reTrack($id: ID!){
     reTrack(id: $id)
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { id: tracking_id })
      .then((res) => {
        message["success"](`Retrack tracking success!`);
        this.setState({ loading: false }, () => this.loadOrders());
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
        this.setState({ loading: false });
      });
  };

  pushToDtf = () => {
    console.log(this.state.pushDtfData)
    
    if (Object.entries(this.state.pushDtfData).length !== this.state.orderSelect.length) {
        notification.error({ message: "Complete order mapping."})

        return
    }

    const orderNotDtf = this.state.orderSelect.find((selected) => parseInt(selected?.supplier?.id) !== this.state.dtfId)

    if (orderNotDtf) {
        notification.error({ message: "Order " + orderNotDtf?.id + " is not from Color Ecommerce."})

        return
    }

    if (Object.keys(this.state.pushDtfData).length === 0) {
        notification.error({ message: "No mapping, please check again."})

        return
    }

    const orderMappingFailed = Object.entries(this.state.pushDtfData)
        .find(([key, value]) => (typeof value?.sku === 'undefined') || (typeof value?.shipping_method === 'undefined') || (value?.placement?.length <1))

    if (orderMappingFailed) {
        const orderIdFailed = orderMappingFailed[0] ?? ""
        notification.error({ message: "Order "+orderIdFailed+" is mapping failed."})

        return
    }

    this.setState({btnPushToDtfLoading: true})

    let data = []

    Object.entries(this.state.pushDtfData).forEach(([key, value]) => {
        let group = data?.find((item) => item.origin_number === value?.origin_number)
        let index = data?.findIndex((item) => item.origin_number === value?.origin_number)

        if (group) {
            const o = group?.orders?.find((i) => i?.order_id === key)

            if (! o) {
                group.orders.push({
                    order_id: parseInt(key),
                    sku: value?.sku,
                    placement: value?.placement,
                    shipping_method: value?.shipping_method
                })
            } 

            if (index !== -1) {
                data.splice(index, 1)
            } 

            data.push(group)
        } else {
            let orders = []
            
            orders.push({
                order_id: parseInt(key),
                sku: value?.sku,
                placement: value?.placement,
                shipping_method: value?.shipping_method
            })

            data.push({
                origin_number: value?.origin_number,
                orders: orders,
            })
        } 
    });

    const mutationPushToDtf = `mutation dtfPushOrder($input: DtfRequestData!){
        dtfPushOrder(input: $input){
            success
            origin_number
            message
        }
    }`

    gql.request(mutationPushToDtf, { 
        input: {
            groups: data
        }
    })
    .then((res) => {
        let dtfPushOrder = res?.dtfPushOrder ?? []

        if (dtfPushOrder?.length > 0) {
            dtfPushOrder.forEach((order) => {
                if (order?.success) {
                    notification.success({ message: `Order #${order?.origin_number} ${order?.message}` })
                } else {
                    notification.error({ message: `Order #${order?.origin_number} ${order?.message}` })
                }
            })

            this.setState({
                pushDtfData: {}, 
                orderSelect: [],
                btnPushToDtfLoading: false
            })
        
            this.loadOrders()

            return
        }

        if (res?.errors?.length > 0) {
            this.setState({
                pushDtfData: {}, 
                orderSelect: [],
                btnPushToDtfLoading: false
            })

            const resErr = res?.errors[0]
            notification.error({ message: resErr })

            return
        }
    })
    .catch((err) => {
        this.setState({
            pushDtfData: {}, 
            orderSelect: [],
            btnPushToDtfLoading: false
        })

        notification.error({ message: _.get(err, '[0].message') })
    })
  }

  handleChangePushToDtf = (id, data) => {
      if (data?.sku && data?.placement?.length > 0 && data?.shipping_method) {
          this.setState({pushDtfState: {
              ...this.state.pushDtfState,
              [id]: {
                  ...this.state.pushDtfState[id],
                  btnPushDtfDisable: false
              }
          }})
      } else {
          this.setState({pushDtfState: {
              ...this.state.pushDtfState,
              [id]: {
                  ...this.state.pushDtfState[id],
                  btnPushDtfDisable: true
              }
          }})
      }

      this.setState({pushDtfData: {
          ...this.state.pushDtfData,
          [id]: data
      }})
  }

  render() {
    const {
      orders,
      aggs,
      total,
      filter,
      loading,
      page,
      selectedOrder,
      showUpdateCustomer,
      showOrderComments,
      showUploadDesign,
      showEntityLogs,
      showEntityLogsTracking,
      showFetchOrders,
      loadingMenuIcon,
      initSetting,
      showImage,
      changeVariant,
      changeQuantity,
      pushDtfState,
    } = this.state;
    
    const setting =
      initSetting &&
      initSetting.reduce(
        (init, item) => ({ ...init, [item.name]: item.value }),
        {}
      );

    const isAdmin = this.props.currentUser.roles.find((role) => ['Administrator'].includes(role.name))

    let columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: 100,
        render: (_, order) => (
          <div>
            <p>{`N${order.id?.padStart(6, "0")}`}</p>
          </div>
        ),
      },
      {
        title: "Origin Number / Origin ID",
        dataIndex: "origin_number",
        width: 200,
        render: (origin_number, order) => (
          <div>
            {origin_number && <p>{`${origin_number}`}</p>}
            <p style={{ fontStyle: "italic" }}>{`${order.origin_id}`}</p>
          </div>
        ),
      },
      {
        title: "Image Mockup",
        dataIndex: "variant_image",
        width: 200,
        render: (variant_image) => (
          <Avatar
            icon="picture"
            src={variant_image}
            shape="square"
            size={60}
            style={{ marginRight: "10px" }}
          />
        ),
      },
      // {
      //   title: "Product Name",
      //   width: 200,
      //   dataIndex: "product_name",
      // },
      {
        title: "product",
        dataIndex: "product_type_variant",
        width: 200,
        render: (product_type_variant, order) => (
          <div>
            <p>
              <b>Product type</b>: {product_type_variant?.product_type.title}
            </p>
            <div>
              {product_type_variant?.attributes &&
                product_type_variant.attributes.map((a, index) => {
                  return (
                    <Fragment key={index}>
                      <p style={{ marginBottom: 0 }}>
                        <b>{a.slug}</b>: {a.option}{" "}
                      </p>
                    </Fragment>
                  );
                })}
            </div>
            {/* <p>
              <b>Sku</b>:{product_type_variant.sku}
            </p> */}
          </div>
        ),
      },
      // {
      //   title: "Sku",
      //   dataIndex: "sku",
      //   width: 200,
      //   render: (_, order) => (
      //     <p style={{ marginBottom: 5 }}>
      //       {order.product_sku ? order.product_sku : order.product?.sku}{" "}
      //     </p>
      //   ),
      // },
      {
        title: "Designs",
        dataIndex: "designs",
        width: 120,
        render: (designs, order) => {
          let status =
            order.personalized || order.custom_order
              ? order.design_status
              : order.variant.design_status;
          return status ? (
            <Button
              icon="check"
              type="primary"
              ghost
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  showUploadDesign: true,
                  selectedOrder: order,
                });
              }}
            >
              View
            </Button>
          ) : (
            <>
              {order?.status === 'pending_design' && (
                <Tooltip title='Designs is downloading, you can upload new designs on Ready status'>
                  <div>Loading...</div>
                </Tooltip>
              )}

              {(isAdmin || order?.status === 'ready' || order?.status === 'error') && (
                <Button
                  icon="warning"
                  type="danger"
                  ghost
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      showUploadDesign: true,
                      selectedOrder: order,
                    });
                  }}
                >
                  Upload
                </Button>
              )}
            </>
          );
        },
      },
      {
        title: "QTY",
        dataIndex: "supplier_cost",
        key: "supplier_cost",
        width: 130,
        render: (id, order) => (
          <div>
            <ContainerHover style={{ display: "flex", gap: 5 }}>
              <span style={{ margin: 5 }}>
                {" "}
                {`${order.quantity}`}{" "}
              </span>
              <Button
                size="small"
                type="link"
                hidden={
                  !_.includes(
                    ["pending_design", "pending", "ready", "processing"],
                    order.status
                  )
                }
                onClick={() => {
                  this.setState({ changeQuantity: order });
                }}
              >
                <Icon type="edit" theme="twoTone" />
              </Button>
            </ContainerHover>
            <p style={{ marginBottom: 0, fontStyle: "italic" }}>
              {moment(order.created_at).format("DD MMM YYYY, HH:mm:ss")}
            </p>
          </div>
        ),
      },
      {
          title: "COST",
          dataIndex: "base_cost",
          key: "base_cost",
          width: 80,
          render: (id, order) => (
              <>
                  {order.base_cost > 0 && (
                    <div>
                      {`$${order.base_cost}`}
                    </div>
                  )}
              </>
          ),
      },
      {
          title: "SHIPPING COST",
          dataIndex: "shipping_cost",
          key: "shipping_cost",
          width: 120,
          render: (id, order) => (
              <>
                  {order.shipping_cost > 0 && (
                    <div>
                      {`$${order.shipping_cost}`}
                    </div>
                  )}
              </>
          ),
      },
      {
        title: "Shipping Method",
        width: 150,
        key: "carrier",
        render: (_, order) => {
          const carriers = this.state.carriers; //order.variant.product_type.product_type_carriers
          const menu = (
            <Menu
              onClick={(item) => {
                let carrier = carriers.find((el) => el.carrier.id == item.key);
                //console.log('item', carrier, item)
                this.assignOrderToCarrier(carrier, order.id);
              }}
            >
              {carriers &&
                carriers.map((el) => (
                  <Menu.Item
                    key={el.carrier ? el.carrier.id : 0}
                    value={el.carrier}
                  > qwe
                    {el.carrier ? el.carrier.name : ""} 
                    {/* ({el.price > 0 ? "$" + el.price : "Free"}) */}
                  </Menu.Item>
                ))}
            </Menu>
          );
          if (!order.carrier) {
            return (
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                onVisibleChange={(visible) => {
                  if (visible) {
                    this.setState({ carriers: [], selectedOrder: order });
                    this.loadOrderCarriers(order.id);
                  }
                }}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Select Carrier{" "}
                  <Icon
                    type={
                      loadingMenuIcon === "loading" &&
                      selectedOrder &&
                      order &&
                      selectedOrder.id === order.id
                        ? "loading"
                        : "down"
                    }
                  />
                </a>
              </Dropdown>
            );
          } else {
            return (
              <div>
                {/* {`${order.carrier.name} (${
                  order.shipping_cost > 0 ? "$" + order.shipping_cost : "Free"
                }) `} */}
                
                {order?.carrier?.name}
                
                {order?.shipping?.method_title && (
                  <p style={{ marginBottom: 0}}>
                    {order?.shipping?.method_title}
                  </p>
                )}

                {["pending", "pending_design", "processing"].includes(
                  order.status
                ) && (
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    onVisibleChange={(visible) => {
                      if (visible) {
                        this.setState({ carriers: [], selectedOrder: order });
                        this.loadOrderCarriers(order.id);
                      }
                    }}
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      Edit{" "}
                      <Icon
                        type={
                          loadingMenuIcon === "loading" &&
                          selectedOrder &&
                          order &&
                          selectedOrder.id === order.id
                            ? "loading"
                            : "down"
                        }
                      />
                    </a>
                  </Dropdown>
                )}
              </div>
            );
          }
        },
      },
      {
        title: "Tracking code",
        width: 220,
        dataIndex: "tracking",
        key: "tracking",
        render: (tracking, order) => {
          if (tracking) {
            let trackingUrl = order.carrier ? order.carrier.url : "";
            if (trackingUrl) {
              trackingUrl = trackingUrl.replace(
                "TRACKING_NUMBER",
                tracking.tracking_code
              );
            }
            return (
              <TrackingWrapper>
                <a href={trackingUrl} target="_blank">
                  {tracking.tracking_code}{" "}
                </a>
                <div className="tracking-actions">
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showEntityLogsTracking: true,
                        selectedOrder: order,
                      });
                    }}
                  >
                    View logs
                  </a>{" "}
                  |{" "}
                  <Button
                    type="link"
                    size="small"
                    // loading={updateTrackingLoadings[tracking.id]}
                    onClick={(e) => {
                      e.preventDefault();
                      this.updateTracking(tracking.id);
                    }}
                  >
                    Update
                  </Button>
                </div>
              </TrackingWrapper>
            );
          }
        },
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        width: 250,
        render: (id, order) => (
          <div>
            <p>{`${order.customer.first_name} ${order.customer.last_name}`}</p>
            <p style={{ marginBottom: 0, fontStyle: "italic" }}>
              {order.customer.email}
            </p>
          </div>
        ),
      },
      {
        title: 'Seller',
        dataIndex: 'seller',
        key: 'site',
        width: 200,
        render: (id, order) =>
          <div>
            <p>{`${order.site && order.site.user ? order.site.user.first_name : ''} ${order.site && order.site.user ? order.site.user.last_name : ''}`}</p>
          </div>
      },
      {
        title: "Suppliers",
        key: "suppilers",
        width: 250,
        render: (_, order) => {
          if (!order?.supplier) {
            return (
              <Select
                style={{ width: 200 }}
                placeholder="Assign to supplier"
                onChange={this.assignOrderToSupplier(order?.id)}
              >
                {order.custom_order
                  ? order?.product_type_variant?.product_type?.suppliers &&
                    order?.product_type_variant?.product_type?.suppliers.map(
                      (el) => (
                        <Select.Option key={el.id} value={el.id}>
                          {el.first_name} {el.last_name}
                        </Select.Option>
                      )
                    )
                  : order?.variant?.product_type?.suppliers &&
                    order?.variant?.product_type?.suppliers.map((el) => (
                      <Select.Option key={el.id} value={el.id}>
                        {el.first_name} {el.last_name}
                      </Select.Option>
                    ))}
                {}
              </Select>
            );
          } else {
            return `${order.supplier.first_name} ${order.supplier.last_name}`;
          }
        },
      },
      {
        title: "Status",
        width: 150,
        // fixed: "right",
        dataIndex: "status",
        render: (status, order) => {
            const item = orderStatus.find((el) => [status].includes(el.key))
            const partner_error = order?.partner_error ?? null
            return (
                <>
                    {item  && (
                        <span style={{ color: `${item.color}` }}>
                            {item.title} 
                        </span>
                    )}

                    {item.key === 'error' && partner_error?.length > 0 && (
                        <div style={{ color: `${item.color}` }}>{partner_error?.map((el) => (
                          <div>{el}</div>
                        ))}</div>
                    )} 
                </>
            )
        },
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "actions",
        align: "right",
        // fixed: "right",
        width: 200,
        render: (id, order) => {
          return (
          <div key={id}>
            {(
              ['ready', 'error'].includes(order.status) 
                  && parseInt(order?.supplier?.id) === this.state.dtfId
                  && isAdmin
              ) &&
              (
                  <>
                      <Fragment>
                        <Tooltip title='DTF data mapping'>
                            <Button
                                onClick={() => {
                                    this.setState({pushDtfState: {
                                        ...pushDtfState,
                                        [order.id]: {
                                            ...pushDtfState[order.id],
                                            show: true
                                        }
                                    }})
                                }}
                            >
                                DTF
                            </Button>
                        </Tooltip>

                        <Divider type="vertical" />
                      </Fragment>

                      <Modal
                          title='DTF data mapping, complete all'
                          visible={pushDtfState[order.id]?.show}
                          onCancel={() => {
                              this.setState({pushDtfState: {
                                  ...pushDtfState,
                                  [order.id]: {
                                      ...pushDtfState[order.id],
                                      show: false
                                  }
                              }})
                          }}
                          footer={[
                              <Button
                                  disabled={pushDtfState[order.id]?.btnPushDtfDisable ?? true}
                                  key={"modal-push-to-dtf"+order.id}
                                  type='primary'
                                  onClick={() => {
                                      this.setState({pushDtfState: {
                                          ...pushDtfState,
                                          [order.id]: {
                                              ...pushDtfState[order.id],
                                              show: false
                                          }
                                      }})
                                  }}
                              >
                                  Save
                              </Button>
                          ]}
                      >
                          {order?.product_type_variant && (
                            <div style={{marginBottom: '15px'}}>
                              <b>Product type</b>: {order?.product_type_variant?.product_type.title}

                              {order?.product_type_variant?.attributes &&
                                order?.product_type_variant.attributes.map((a, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <p style={{ marginBottom: 0 }}>
                                        <b>{a.slug}</b>: {a.option}{" "}
                                      </p>
                                    </Fragment>
                                  );
                                })}
                            </div>
                          )}
                          
                          <PushToDtf
                              partnerFulfillmentId={order.supplier.partner_fulfillment_id}
                              printFiles={order.personalized ? order.print_files?.map(item => ({
                                  id: item?.product_type_print_file_id,
                                  title: item?.title,
                                  file_id: item?.fileID,
                              })) : order.variant.print_files}
                              onChange={(data) => {
                                  data.origin_number = order?.origin_number
                                  this.handleChangePushToDtf(order.id, data)
                              }}
                              stateValue={this.state.pushDtfData[order.id]}
                              partnerVariant={order?.product_type_variant?.partner_variants?.[0] ?? order?.variant?.product_type_variant?.partner_variants?.[0]}
                              mappingPartnerPrintAreas={order?.product_type_variant?.mapping_partner_print_areas ?? order?.variant?.product_type_variant?.mapping_partner_print_areas}
                          />
                      </Modal>    
                  </>
              )} 

            {/* <OrderAction id={id} order={order} /> */}
            {order.variant.product_type?.state !== "paused" &&
              ["pending", "pending_design", "processing"].includes(
                order.status
              ) && (
                <>
                  <Tooltip
                    title={
                      order.status !== "pending"
                        ? "Pause Order"
                        : "Resume Order"
                    }
                  >
                    <Button
                      icon={
                        order.status !== "pending" ? "pause" : "step-forward"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.pauseOrResumeOrder(id, order.status);
                      }}
                      style={{ color: "#1890ff" }}
                    />
                  </Tooltip>
                  <Divider type="vertical" />
                </>
              )}

            {["processing"].includes(order.status) && (
              <Fragment>
                <Tooltip title="Enter Payment">
                  <Popconfirm
                    title="Enter Payment for this order?"
                    onConfirm={() => this.enterPayment(id)}
                  >
                    <Button icon="dollar" />
                  </Popconfirm>
                </Tooltip>
                <Divider type="vertical" />
              </Fragment>
            )}

            {(["pending", "pending_design", "processing", "ready"].includes(order.status) || (isAdmin && ["error"].includes(order.status))) && (
              <Fragment>
                <Tooltip title="Cancel Order">
                  <Popconfirm
                    title="Cancel this order?"
                    onConfirm={() => this.cancelOrder(id)}
                  >
                    <Button style={{ color: "red" }} icon="close" />
                  </Popconfirm>
                </Tooltip>
                <Divider type="vertical" />
              </Fragment>
            )}

            {(isAdmin && ['processing', 'error'].includes(order.status)) && (
              <Fragment>
                  <Tooltip title='Ready'>
                      <Popconfirm
                          title='To ready?'
                          onConfirm={() => this.updateStatus(id, 'ready')}>
                          <Button
                              style={{ color: 'rgb(102, 204, 204)' }}
                              size='small'
                          >Ready</Button>
                      </Popconfirm>
                  </Tooltip>
                  <Divider type='vertical' />
              </Fragment>
            )}

            {(isAdmin && ['in_production'].includes(order.status)) && (
              <Fragment>
                  <Tooltip title='Fulfilled'>
                      <Popconfirm
                          title='To fulfilled?'
                          onConfirm={() => this.updateStatus(id, 'fulfilled')}>
                          <Button
                              style={{ color: 'rgb(82, 196, 26)' }}
                              size='small'
                          >Fulfilled</Button>
                      </Popconfirm>
                  </Tooltip>
                  <Divider type='vertical' />
              </Fragment>
            )}

            {(isAdmin && ['ready', 'error'].includes(order.status)) && (
              <Fragment>
                  <Tooltip title='In production'>
                      <Popconfirm
                          title='To in production?'
                          onConfirm={() => this.updateStatus(id, 'in_production')}>
                          <Button
                              style={{ color: 'rgb(0, 207, 255)' }}
                              size='small'
                          >In production</Button>
                      </Popconfirm>
                  </Tooltip>
                  <Divider type='vertical' />
              </Fragment>
            )}

            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Button
                      type={"link"}
                      style={{ width: "100%", color: "rgba(0,0,0,0.56)" }}
                      icon="idcard"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showUpdateCustomer: true,
                          selectedOrder: order,
                        });
                      }}
                    >
                      Customer Info
                    </Button>
                  </Menu.Item>
                  {/* <Menu.Item key="3">
                  <Button icon="message" style={{width: "100%"}}
                   onClick={e => {
                    e.preventDefault()
                    this.setState({
                      showOrderComments: true,
                      selectedOrder: order
                    })
                  }}>Notes</Button>
                </Menu.Item> */}
                  <Menu.Item key="2">
                    <Button
                      type={"link"}
                      style={{
                        color: "rgba(0,0,0,0.56)",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showEntityLogs: true,
                          selectedOrder: order,
                        });
                      }}
                      icon="message"
                    >
                      History
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Icon type="dash" />
            </Dropdown>
          </div>
        )},
      },
    ];
    if (
      !this.props.currentUser.roles
        .map((role) => role.name)
        .includes("Administrator")
    ) {
      columns = columns.filter((item) => item.title !== "Suppliers");
    }

    const orderStatus = [
      {
        key: "pending",
        title: "Pending",
        color: "#ffd1d1",
      },
      {
        key: "pending_design",
        title: "Pending Designs",
        color: "#ffaeb4",
      },
      {
        key: "processing",
        title: "Processing",
        color: "#69ebf9",
      },
      {
        key: "ready",
        title: "Ready",
        color: "#66cccc",
      },
      {
        key: "in_production",
        title: "In Production",
        color: "#00cfff",
      },
      {
        key: "fulfilled",
        title: "Fulfilled",
        color: "#52c41a",
      },
      // {
      //   key: 'refunded',
      //   title: 'Refunded',
      //   color: '#ff5500'
      // },
      {
        key: "cancelled",
        title: "Cancelled",
        color: "red",
      },
      {
        key: "error",
        title: "Error",
        color: "red",
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState(
          {
            page: page,
            filter: {
              ...filter,
              offset: (page - 1) * pageSize,
            },
          },
          () => this.loadOrders()
        );
      },
    };

    const alert = (
      <div dangerouslySetInnerHTML={{ __html: setting["seller_message"] }} />
    );

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ orderSelect: selectedRows })
        this.props.setOrderId(selectedRowKeys);
      },
    };

    const extraPageHeader = <div style={{display:'flex', alignItems:'center'}}>
            <OrdersToolbar menu={true} loadOrder={() => this.loadOrders()} />

            {isAdmin && (
              <Button
                  type={'primary'}
                  loading={this.state.btnPushToDtfLoading} 
                  disabled={this.state.orderSelect?.length < 1}
                  onClick={() => this.pushToDtf()}
              >
                  Push DTF
              </Button>
            )}
        </div>

    return (
      <div>
        {/* <SellerOrderFilter {...this.props} /> */}
        <PageHeader
          title="Api Orders"
          onBack={() => {
            history.goBack();
          }}
          style={{ padding: "16px 0px" }}
          extra={extraPageHeader}
        />
        {setting && setting["seller_message"] && (
          <Alert style={{ marginBottom: 20 }} type="error" message={alert} />
        )}
        <Row type="flex" style={{ width: "calc(100%)" }}>
          <Col span={24} style={{ display: "flex", alignItems: "center" }}>
            <SellerOrderFilter {...this.props} />
          </Col>
        </Row>
        <Tabs
          onChange={this.statusFilter}
          style={{ marginBottom: 0 }}
          activeKey={filter.status ? filter.status[0] : "all"}
        >
          <Tabs.TabPane
            tab={
              <Badge
                overflowCount={9999999}
                count={_.sum(aggs.map((agg) => agg.doc_count))}
                style={{
                  background: "#fff",
                  color: "#000",
                  border: "1px solid #f5f5f5",
                  right: -20,
                }}
              >
                All
              </Badge>
            }
            key="all"
          ></Tabs.TabPane>
          {orderStatus.map((s) => (
            <Tabs.TabPane
              key={s.key}
              tab={
                <Badge
                  overflowCount={9999999}
                  count={_.sum(
                    aggs
                      .filter((agg) => agg.key === s.key)
                      .map((a) => a.doc_count)
                  )}
                  style={{ backgroundColor: s.color }}
                >
                  {s.title}
                </Badge>
              }
            />
          ))}
        </Tabs>
        <Table
          scroll={{ x: tableWidth }}
          columns={columns}
          dataSource={orders}
          pagination={pagination}
          rowKey={(record) => {
            return record.id;
          }}
          loading={loading}
          isShowScroll={true}
          rowSelection={rowSelection}
        />
        <Drawer
          title={`Update customer info #${
            selectedOrder ? selectedOrder.origin_id : ""
          }`}
          onClose={() =>
            this.setState({
              showUpdateCustomer: false,
              selectedOrder: null,
            })
          }
          width="500px"
          visible={showUpdateCustomer}
        >
          {selectedOrder && (
            <CustomerInfo
              onClose={() =>
                this.setState({
                  showUpdateCustomer: false,
                  selectedOrder: null,
                })
              }
              onUpdate={() =>
                this.setState(
                  { showUpdateCustomer: false, selectedOrder: null },
                  () => this.loadOrders()
                )
              }
              order={selectedOrder}
            />
          )}
        </Drawer>
        <Drawer
          title={`Comments #${selectedOrder ? selectedOrder.origin_id : ""}`}
          onClose={() =>
            this.setState({
              showOrderComments: false,
              selectedOrder: null,
            })
          }
          width="500px"
          visible={showOrderComments}
        >
          {selectedOrder && <Comments order_id={selectedOrder.id} />}
        </Drawer>
        <Drawer
          width="800px"
          title="Upload Designs"
          onClose={(e) =>
            this.setState({ selectedOrder: null, showUploadDesign: false })
          }
          visible={showUploadDesign}
        >
          {/* {selectedOrder && (
            <UploadDesigns
              product_id={selectedOrder.product.id}
              product_type_id={selectedOrder.variant.product_type_id}
              onClose={(e) =>
                this.setState(
                  { selectedOrder: null, showUploadDesign: false },
                  () => this.loadOrders()
                )
              }
            />
          )} */}
          {selectedOrder &&
            !selectedOrder.personalized &&
            !selectedOrder.custom_order && (
              <UploadDesigns
                product_id={selectedOrder.product.id}
                product_variant_id={selectedOrder.variant.id}
                product_type_id={selectedOrder.variant.product_type_id}
                onClose={(e) =>
                  this.setState(
                    {
                      selectedOrder: null,
                      showUploadDesign: false,
                      selectedOrder: null,
                    },
                    () => this.loadOrders()
                  )
                }
              />
            )}
          {selectedOrder &&
            (selectedOrder.personalized || selectedOrder.custom_order) && (
              <UploadDesignPersonal
                selectedOrder={selectedOrder}
                onClose={(e) =>
                  this.setState({
                    selectedOrder: null,
                    showUploadDesign: false,
                    selectedOrder: null,
                  })
                }
              />
            )}
        </Drawer>
        <Drawer
          width="500px"
          title="Activities"
          onClose={(e) =>
            this.setState({ selectedOrder: null, showEntityLogs: false })
          }
          visible={showEntityLogs}
        >
          <EntityLogs order="Order" selectedOrder={selectedOrder} />
        </Drawer>
        <Drawer
          width="500px"
          title="View logs"
          onClose={(e) =>
            this.setState({
              selectedOrder: null,
              showEntityLogsTracking: false,
            })
          }
          visible={showEntityLogsTracking}
        >
          <EntityLogs
            tracking="Tracking"
            selectedOrder={selectedOrder?.tracking}
          />
        </Drawer>
        {this.props.currentSite?.type !== "offline" && (
          <Drawer
            width="500px"
            title="Fetch Orders"
            onClose={(e) => {
              this.setState({ showFetchOrders: false });
            }}
            visible={showFetchOrders}
          >
            <FetchOrders
              site_id={this.props.currentSite?.id}
              onClose={(e) => this.setState({ showFetchOrders: false })}
              refetch={() => this.loadOrders()}
              currentSite={this.props.currentSite}
            />
          </Drawer>
        )}
        <Modal
          title="Image product"
          visible={showImage ? true : false}
          footer={false}
          onCancel={() => {
            this.setState({ showImage: false });
          }}
        >
          <ProductCarousel>
            {showImage && (
              <Carousel autoplay>
                {showImage?.map((item, index) => (
                  <img key={index} src={item} width={400} />
                ))}
              </Carousel>
            )}
          </ProductCarousel>
        </Modal>
        {changeVariant && (
          <Modal
            title="Change variant of product"
            visible={changeVariant ? true : false}
            onCancel={() => {
              this.setState({ changeVariant: false });
            }}
            footer={false}
          >
            <ChangeVariant
              order={changeVariant}
              refetch={() => {
                this.loadOrders();
                this.setState({ changeVariant: false });
              }}
            />
          </Modal>
        )}
        {changeQuantity && (
          <Modal
            title="Change quantity"
            visible={changeQuantity ? true : false}
            onCancel={() => {
              this.setState({ changeQuantity: false });
            }}
            footer={false}
          >
            <ChangeQuantity
              order={changeQuantity}
              refetch={() => {
                this.setState({ changeQuantity: false });
                this.loadOrders();
              }}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    filter: state.orders.filter,
    currentUser: state.app.currentUser,
    currentSite: state.app.currentSite,
  }),
  { setOrdersFilter, setOrderId, resetOrderId }
)(ExternalOrders);
